import React from 'react'
import Site from '../components/Layout/Site'
import DefaultVector from '../components/Shared/DefaultVector'
import { Col, Container, Row } from '../components/UI/Grid'
import { Section } from '../components/UI/Section'
import { Heading } from '../components/UI/Typography'
import { StaticImage } from 'gatsby-plugin-image'

const TestCapability = () => {
  return (
    <Site>
      <Section defaultVector py={{ _: 16, lg: 0 }}>
        <DefaultVector>
          <Container pb={10}>
            <Heading size="h2" mb={10} textAlign="center">
              SiC Process Capability
            </Heading>
            <StaticImage
              src="../images/Fastech_SiC_Process_Capability.jpg"
              alt="banner"
              layout="fullWidth"
            />
          </Container>
        </DefaultVector>
      </Section>
    </Site>
  )
}

export default TestCapability
